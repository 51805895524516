html, body{
    height: 100%;
}
.primaryContainer {
    height: 100%;
    margin-left: auto;
    margin-right: auto;
    min-height: 100%;
    width: 100%;
    position: relative;
}

#videoGame {
    float: left;
    height: 100%;
    margin-left: 0%;
    margin-top: 0px;
    clear: none;
    width: 400px;
    min-height: 0px;
    margin-right: 0px;
}

.HeaderJuego {
	background: -webkit-linear-gradient(90deg, rgb(243, 208, 49) 2%, rgb(247, 227, 131) 100%);
	border-left: 1px solid rgb(172, 172, 172);
	height: 145px;

}
.mainFocus{
    top: 0px;
    height: 100%;
    left: 400px;
	position: absolute;
	right: 0;
}
.VideoDisplayStyle {
    float: left;
    height: 300px;
    margin-left: 0%;
    margin-top: 0px;
    clear: none;
    width: 100%;
    min-width: 0px;
    background-color: #000;
}
.logoPrincipal{
    left: 55px;
    width: 300px;
    position: absolute;
    top: 218px;
    z-index: 1;
}
.GamType {
    float: left;
    height: calc(100% - 300px); /* 100vh - 300px; /*calc(400px + 200px);*/
    margin: 0px;
    clear: both;
    width: 100%;
    min-width: 0px;
    /*background-color: rgb(221, 222, 209);*/
    position: relative;
    border-top-color: #000;
    border-top-width: 1px;
    border-top-style: solid;
    vertical-align: middle;
    background: rgb(102,74,125);
    background: radial-gradient(circle, rgba(102,74,125,1) 0%, rgba(48,14,80,1) 100%)
    
}
.GameTypeContainer{
    min-height:250px;
    width: 100%;
    height:50%;
    margin-top: 160px;
}

.labelTipoJuegoStyle {
    float: left;
    font-size: 25px;
    width: 100%;
    height: auto;
    text-align: center;
    font-weight: 700;
    line-height: 1em;
margin-bottom: 10px;
letter-spacing: 10px;
    clear: none;
    min-height: 0px;
    font-family: bilo;
    margin-right: 0px;
    margin-bottom: 20px;
    background: -webkit-linear-gradient(#ffffff, #999999);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.d_labelEstiloTablasStyle {
    float: left;
    font-size: 25px;
    width:100%;
    height: auto;
    text-align: center;
    letter-spacing: 10px;
    font-weight: 700;
    line-height: 1em;
    clear: both;
    min-height: 0px;
    display: block;
    font-family: bilo;
    position: static;
    top: 0px;
    left: 0px;
    padding-bottom: 2%;
    margin-top: 10px;
    margin-right: 0px;
    background: -webkit-linear-gradient(#ffffff, #999999);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;

   

    
}

.d_tituloTipoJuegoStyle {
    
    float: left;
    font-size: 70px;
    width: 96%;
    height: auto;
    text-align: center;
    font-weight: 700;
    line-height: 1em;
    clear: both;
    min-height: 0px;
    display: block;
    font-family: bello-caps;
    color: rgb(214, 68, 52);
    position: static;
    top: 0px;
    left: 0px;
    padding-bottom: 0%;
    margin-top: 0px;
    margin-left: 2%;
    margin-right: 0px;
  
    background: -webkit-linear-gradient(#f1d231, #f3e48a);
    -webkit-background-clip: text;
    letter-spacing: 3px;
    -webkit-text-fill-color: transparent;    
    
}


.UITipoJuegoStyle {
    float: left;
    height: 50%;
    min-height: 200px;
    clear: both;
    width: 100%;
    position: static;
    margin-bottom: 5px;
    
}

.UITablaStyle {
    float: none;
    height: 100%;
    margin-left: auto;
    margin-top: 0px;
    clear: none;
    width: 35%;
    min-height: 0px;
    border: 1px solid rgb(222, 222, 222);
    margin-right: auto;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
    padding: 5px;
}

.CartaTipoJuego {
    float: left;
    height: 20.498321%;
    margin: 4% 2% 2%;
    clear: none;
    width: 20.984848%;
    background-color: rgba(255, 255, 255, 0.45);
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;

    
}
.CartaTipoJuegoObjetivo {
    float: left;
    height: 20.498321%;
    margin: 4% 2% 2%;
    clear: none;
    width: 20.984848%;
    box-shadow: rgb(0, 0, 0) 0px 3px 3px 0px;
    background-color: #f1d231;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
}

.Header {
    float: left;
    height: auto;
    margin: 0px 20px;
    clear: none;
    width: 96%;
    min-width: 0em;
}

#d_tituloJuego {
    float: left;
    font-size: 35px;
    width: 100%;
    height: auto;
    text-align: center;
    font-weight: 700;
    line-height: 1em;
    margin: 0px 0%;
    clear: right;
    min-height: 0px;
    font-family: bello-caps;
    display: block;
    min-width: 0px;
    color: rgb(0, 0, 0);
}

#UIReloj {
    float: right;
    height: auto;
    margin-left: 0px;
    margin-top: 50px;
    clear: none;
    width: auto;
    margin-right: 0px;
}

#labelHoraActual {
    float: left;
    font-size: 1em;
    width: 100%;
    text-align: right;
    font-weight: 700;
    line-height: 1em;
    clear: none;
    min-height: 0px;
    display: block;
    font-family: bilo;
    position: static;
    top: 0px;
    left: 0px;

    margin: 0px;
    min-width: 0px;
    color: rgb(0, 0, 0);
}
.divtitulosJuego{
    position: absolute;
	text-align: left;
    z-index: 100;
    top: 5px;
    left: 10px;
    width: calc(100%- 10x);

}

.titulosJuego{
    font-size: 60px;
    font-family: bello-caps;
    background: -webkit-linear-gradient( rgba(255, 136, 55, 0.909804),rgb(255, 188, 0),rgba(255, 128, 40, 0.909804));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;   
    -webkit-text-stroke-width: 2px;
    -webkit-text-stroke-color: black;
    float: left;
 
}
.monedaStyle{float:left; width: 43px}
.labelGanador {
    float: none;
    width: 98%;
    height: auto;
    text-align: center;
    margin: 0px auto 10px;
    clear: none;
    display: block;
    font-family: bello-caps;
    background: -webkit-linear-gradient( rgba(255, 136, 55, 0.909804),rgb(255, 188, 0),rgba(255, 128, 40, 0.909804));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;   
    -webkit-text-stroke-width: 3px;
    -webkit-text-stroke-color: black;
    font-size: 60px;
    margin-top: 10px;
}
.EnVivo{
    position: fixed; background-color: red; width: auto; padding: 5px 10px; border-radius: 20px; right: 10px; top: 10px;
}
.EnViVoText{
    font-family: bilo; font-size: 18px; color: #fff;
}
.divJuegoSombra {   
    text-align: left;    
	left: 40px;
	position: absolute;
    top: 10px;
    left: 10px;
}

.titulosJuegoSombra {
    float:left;
    font-size: 60px;
    font-family: bello-caps;
    color: black;
    float:left;	
}
.StyleAJuego{
    float:left; margin-left: 20px;
}
.HeaderVistozo{
    
    position: absolute;
    top: 60px;
    height: 10px;
    left: 0px;
    right: 0px;
}
.styleJugada{float: left; margin-top: 20px; margin-left: 60px}
.fechaJuego{
    font-size: 35px;
    font-family: bilo;
    color: black;    
    margin-top: 0px;
    position: relative; top: 15px;
    
}
.styleDinero{
    margin-top: -6px; 
    float: left; 
    font-family: bello-caps; 
    font-size: 45px; 
    color: white; 
    font-shadow: rgb(0, 0, 0) 2px 4px 3px 0px; 
    text-shadow: 0px 4px 4px rgba(0,0,0,0.52); 
    padding-left: 20px;
}
.fechaJuegot{
    position: relative; top: 5px;
    float: left;

}
.horaActuals{
    float: left; position: relative

}
.imgstyleChampions{
    width: 40px;
}
.styleChampions{
    float: left; border: 1px solid black; margin-top: 18px; margin-left: 30px; width: 50px; height: 50px;  border-radius: 30px; padding: 5px; padding-top: 8px;
}
.divPremio{
    float: left; 
    background: -webkit-linear-gradient(270deg, rgba(0, 0, 0, 0.901961) 4%, rgba(0, 0, 0, 0.639216) 100%);
    height: 45px; padding-right: 20px; padding-left: 20px;
     border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px; 
    margin-left: 20px
}



.d_timeStyle {
    float: left;
    font-size: 60px;
    width: 100%;
    height: auto;
    text-align: right;
    font-weight: 700;
    line-height: 1em;
    clear: both;
    min-height: 0px;
    display: block;
    font-family: bello-caps;
    position: static;
    top: 0px;
    left: 0px;
    padding-bottom: 0%;
    min-width: 0px;
    color: rgb(0, 0, 0);
}

.tablero {
    float: left;
    height: calc(100% - 145px);
    margin: 0px;
    clear: both;
    width: 100%;
    min-width: 0px;
    
    border-top-color: rgb(172, 172, 172);
    border-top-width: 1px;
    border-top-style: solid;
    position: relative;
    background:#42756a;
    background: radial-gradient(circle,#42756a 0%, #2e3a36)
    
}
#UIInteraction {
    float: left;
    height: 100%;
    clear: none;
    width: calc(100% - 490px);
    position: absolute;
    top: 1px;
    left: 0%;
    right: 477.328125px;
    bottom: auto;
}

#UILogs {
    float: right;
    height: calc(100%-20px);
    margin-top: 20px;
    padding-bottom: 20px;
    clear: none;
    width: 440px;
    min-width: 0px;
    overflow: scroll;
}

#labelHistorial {
    float: left;
    font-size: 20px;
    color: white;
    width: auto;
    height: auto;
    text-align: left;
    font-weight: 700;
    line-height: 1em;
    clear: none;
    min-height: 0px;
    display: block;
    font-family: bilo;
    position: static;
    top: 0px;
    left: 0px;
    padding: 0px;
    margin: 0px;
    min-width: 0px;
}

#UIHistorial {
    position: absolute;
    right: 10px;
    top: 60px;
    bottom: 10px;
    padding-bottom: 20px;
    width: 440px;
    clear: none;
    overflow: scroll;
}

.UILogCarta {
    float: left;
    height: 142px;
    margin: 0px 8px 0px 0px;
    clear: none;
    width: 80px;
    display: block;
    min-height: 0px;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
    border-bottom-left-radius: 3px;
}

.ImagenCartaLog {
    float: left;
    height: 73%;
    margin: 0px 0%;
    clear: none;
    width: 100%;
    display: block;
    min-height: 0px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
    /*background-image:url("img/RANA%201.jpg");
    background-attachment: scroll;
    -webkit-background-size: 100%;
    background-size: 100%;
    min-width: 0px;
    background-position: 0px 0px;
    background-repeat: repeat repeat;*/
}

.TituloCartaLog{
    float: left;
    font-size: 12px;
    color: white;
    width: 100%;
    height: auto;
    text-align: center;
    font-weight: 700;
    line-height: 1em;
    clear: both;
    min-height: 0px;
    display: block;
    font-family: bilo;
    position: static;
    top: 0px;
    left: 0px;
    padding: 0px;
    margin: 5px 0px 0px;
    min-width: 0px;
}




.UITablaPlayerStyle {
    float: left;
    height: 450px;
    margin: 0px;
    clear: none;
    width: 100%;
    min-height: 0px;
    border: 1px solid rgb(126, 126, 126);
    min-width: 0px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
}

.cartaStyle {
    position: relative;
    float: left;
    height: 20.498321%;
    margin: 4% 2% 2%;
    clear: none;
    width: 21%;
    

    -webkit-background-size: 100%;
    background-size: 100%;

    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;


}
.cartaActiva {
    position: relative;
    float: left;
    height: 20.498321%;
    margin: 4% 2% 2%;
    clear: none;
    width: 21%;
    

    
    -webkit-background-size: 100%;
    background-size: 100%;
    background-repeat: repeat repeat;

    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
    
    border: 4px solid rgba(243, 49, 49, 0.909804);
    
}

#box {
    float: left;
    height: 20.498321%;
    margin: 4% 2% 2%;
    clear: none;
    width: 20.984848%;
    background-image:url("img/RANA.jpg");
    background-attachment: scroll;
    -webkit-background-size: 100%;
    background-size: 100%;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
    background-position: 0% 0%;
    background-repeat: repeat repeat;
}

#box1 {
    float: left;
    height: 20.498321%;
    margin: 4% 2% 2%;
    clear: none;
    width: 20.984848%;
    background-color: rgb(255, 255, 255);
    background-image:url("img/RANA.jpg");
    background-attachment: scroll;
    -webkit-background-size: 100%;
    background-size: 100%;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
    background-position: 0% 0%;
    background-repeat: repeat repeat;
}



#box2 {
    float: left;
    height: 20.498321%;
    margin: 4% 2% 2%;
    clear: both;
    width: 20.984848%;
    display: block;
    background-color: rgb(255, 255, 255);
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
}

#carta1 {
    float: left;
    height: 20.498321%;
    margin: 4% 2% 2%;
    clear: none;
    width: 20.984848%;
    display: block;
    background-color: rgb(255, 255, 255);
    background-image:url("img/RANA.jpg");
    background-attachment: scroll;
    -webkit-background-size: 100%;
    background-size: 100%;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
    background-position: 0% 0%;
    background-repeat: repeat repeat;
}

#box3 {
    float: left;
    height: 20.498321%;
    margin: 4% 2% 2%;
    clear: none;
    width: 20.984848%;
    display: block;
    background-color: rgb(255, 255, 255);
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
}

#box4 {
    float: left;
    height: 20.498321%;
    margin: 4% 2% 2%;
    clear: none;
    width: 20.984848%;
    display: block;
    background-color: rgb(255, 255, 255);
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
}

#box5 {
    float: left;
    height: 20.498321%;
    margin: 4% 2% 2%;
    clear: both;
    width: 20.984848%;
    display: block;
    background-color: rgb(255, 255, 255);
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
}

#box6 {
    float: left;
    height: 20.498321%;
    margin: 4% 2% 2%;
    clear: none;
    width: 20.984848%;
    display: block;
    background-color: rgb(255, 255, 255);
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
}

#box7 {
    float: left;
    height: 20.498321%;
    margin: 4% 2% 2%;
    clear: none;
    width: 20.984848%;
    display: block;
    background-color: rgb(255, 255, 255);
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
}

#box8 {
    float: left;
    height: 20.498321%;
    margin: 4% 2% 2%;
    clear: none;
    width: 20.984848%;
    display: block;
    background-color: rgb(255, 255, 255);
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
}

#box9 {
    float: left;
    height: 20.498321%;
    margin: 4% 2% 2%;
    clear: both;
    width: 20.984848%;
    display: block;
    border: 1px solid rgb(208, 0, 0);
    background-color: rgb(214, 68, 52);
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
}

#box10 {
    float: left;
    height: 20.498321%;
    margin: 4% 2% 2%;
    clear: none;
    width: 20.984848%;
    display: block;
    background-color: rgb(255, 255, 255);
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
}

#box11 {
    float: left;
    height: 20.498321%;
    margin: 4% 2% 2%;
    clear: none;
    width: 20.984848%;
    display: block;
    background-color: rgb(255, 255, 255);
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
}

#box12 {
    float: left;
    height: 20.498321%;
    margin: 4% 2% 2%;
    clear: none;
    width: 20.984848%;
    display: block;
    border: 1px solid rgb(208, 0, 0);
    background-color: rgb(214, 68, 52);
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
}

#nombreCarta {
    float: left;
    font-size: 2em;
    width: 100%;
    height: auto;
    text-align: center;
    font-weight: 700;
    line-height: 1em;
    clear: both;
    min-height: 0px;
    display: block;
    font-family: bilo;
    color: rgb(255, 255, 255);
    position: static;
    top: 0px;
    left: 0px;
    padding-bottom: 0%;
    margin-top: 14.5px;
    margin-left: -0.0103826%;
    margin-right: 0px;
    min-width: 0px;
    text-shadow: 0px 2px 3px rgb(0, 0, 0),  0px 3px 2px rgb(0, 0, 0);
}

#UIListadoTablasJugador {
    position: relative;
    height: 100%;
    width: 96%;
    min-width: 0px;
    padding-right: 2%;

}
.UITablasJugador {
    vertical-align: middle;
    
    clear: none;
    width: 300px;
    position: static;
    top: 0px;
    left: 20px;
    margin-left: 10px;
    margin-right: 10px;
    margin-top: 20px;
    margin-bottom: 60px;
    display:inline-block;
    
}


.UICartasJuegador {
    
    background-color: rgba(0, 0, 0, 0.631373);
    top: 0px;
    right: 0px;
    left: 0px;
    bottom: 0px;
    min-width: 0px;
    position: absolute;
    overflow-y: scroll;
    z-index:3;
}

#d_nombreJugador {
    float: left;
    font-size: 30px;
    width: auto;
    height: auto;
    text-align: left;
    font-weight: 700;
    line-height: 1em;
    margin: 20px;
    clear: none;
    min-height: 0px;
    font-family: bilo;
    display: block;
    min-width: 0px;
    color: rgb(255, 255, 255);
}

.d_cartaSelecionadaAplica {
    float: left;
    height: 50px;
    margin-left: 2px;
    margin-top: 17px;
    clear: none;
    width: 50px;
    border-top-left-radius: 25px;
    border-top-right-radius: 25px;
    border-bottom-right-radius: 25px;
    border-bottom-left-radius: 25px;
    background-color: rgba(243, 49, 49, 0.80);
    border: 4px solid rgb(227, 0, 0);
    -webkit-box-shadow: rgb(0, 0, 0) 2px 4px 3px 0px;
    box-shadow: rgb(0, 0, 0) 2px 4px 3px 0px;
}
.nombreCartaJugador{
    color: #000;
    text-shadow: 0px 1px 1px rgb(255, 255, 255),  0px -1px 2px rgb(255, 255, 255),  0px 0px 2px rgb(255, 255, 255);
    
    
    position: absolute;
    font-family: bilo;
    font-weight: 800;
    font-size: 10px;
    font-variant-caps:all-small-caps;
    bottom: 5px;
    left: 0px;
    text-align: center;
    width: 100%;
}
#d_cartaSelecionadaNoAplica {
    float: left;
    height: 50px;
    margin-left: 5px;
    margin-top: 21px;
    clear: none;
    width: 50px;
    border-top-left-radius: 25px;
    border-top-right-radius: 25px;
    border-bottom-right-radius: 25px;
    border-bottom-left-radius: 25px;
    border: 4px solid rgb(255, 255, 255);
    -webkit-box-shadow: rgb(0, 0, 0) 2px 4px 3px 0px;
    box-shadow: rgb(0, 0, 0) 2px 4px 3px 0px;
    background-color:  rgb(255, 255, 255, 0.60);
    margin-right: 0%;
}

#UIGanador {
   position: absolute;
   top: 0px;
   left: 0px;
   right:  0px;
   bottom: 0px;
    background-color: rgba(243, 208, 49, 0.95);
    min-width: 0px;
    overflow-x: scroll;
    overflow-y: scroll;
    height: 100%;
z-index: 3;
}



#auto {
    float: none;
    height: auto;
    margin: 0px auto;
    margin-top: 30px;
    margin-bottom: 10px;
    clear: none;
    width: 98%;
    min-width: 0px;
    padding-right: 2%;
}

#GroupJuego {
    float: left;
    height: 501.56px;
    clear: none;
    width: 300.98437452281246px;
    position: static;
    top: 0px;
    left: 0px;
    margin: 0px;
}

#TipoJuego {
    float: left;
    height: 89.28972%;
    margin: 0px;
    clear: none;
    width: 100%;
    min-height: 0px;
    border: 1px solid rgb(222, 222, 222);
    min-width: 0px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
}

#box13 {
    float: left;
    height: 20.498321%;
    margin: 4% 2% 2%;
    clear: none;
    width: 20.984848%;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
    background-image:url("img/RANA.jpg");
    background-attachment: scroll;
    -webkit-background-size: 100%;
    background-size: 100%;
    background-color: rgb(214, 68, 52);
    background-position: 0% 0%;
    background-repeat: repeat repeat;
}

#box14 {
    float: left;
    height: 50px;
    margin-left: 6.93749998703125px;
    margin-top: 21.046875px;
    clear: none;
    width: 50px;
    border-top-left-radius: 25px;
    border-top-right-radius: 25px;
    border-bottom-right-radius: 25px;
    border-bottom-left-radius: 25px;
    border: 2px solid rgb(227, 0, 0);
    -webkit-box-shadow: rgb(0, 0, 0) 2px 4px 3px 0px;
    box-shadow: rgb(0, 0, 0) 2px 4px 3px 0px;
    background-color: rgba(243, 49, 49, 0.909804);
}

#box15 {
    float: left;
    height: 20.498321%;
    margin: 4% 2% 2%;
    clear: none;
    width: 20.984848%;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
    background-image:url("img/RANA.jpg");
    background-attachment: scroll;
    -webkit-background-size: 100%;
    background-size: 100%;
    background-position: 0% 0%;
    background-repeat: repeat repeat;
}

#box16 {
    float: left;
    height: 20.498321%;
    margin: 4% 2% 2%;
    clear: none;
    width: 20.984848%;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
    background-image:url("img/RANA.jpg");
    background-attachment: scroll;
    -webkit-background-size: 100%;
    background-size: 100%;
    background-color: rgb(255, 255, 255);
    background-position: 0% 0%;
    background-repeat: repeat repeat;
}

#box17 {
    float: left;
    height: 20.498321%;
    margin: 4% 2% 2%;
    clear: none;
    width: 20.984848%;
    border: 4px solid rgba(243, 49, 49, 0.909804);
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
    background-image:url("img/RANA.jpg");
    background-attachment: scroll;
    -webkit-background-size: 100%;
    background-size: 100%;
    background-color: rgb(214, 68, 52);
    background-position: 0% 0%;
    background-repeat: repeat repeat;
}

#box18 {
    float: left;
    height: 20.498321%;
    margin: 4% 2% 2%;
    clear: both;
    width: 20.984848%;
    display: block;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
    background-color: rgb(255, 255, 255);
}

#box19 {
    float: left;
    height: 20.498321%;
    margin: 4% 2% 2%;
    clear: none;
    width: 20.984848%;
    display: block;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
    background-image:url("img/RANA.jpg");
    background-attachment: scroll;
    -webkit-background-size: 100%;
    background-size: 100%;
    background-color: rgb(255, 255, 255);
    background-position: 0% 0%;
    background-repeat: repeat repeat;
}

#box20 {
    float: left;
    height: 50px;
    margin-left: 8.34375px;
    margin-top: 17.59375px;
    clear: none;
    width: 50px;
    border-top-left-radius: 25px;
    border-top-right-radius: 25px;
    border-bottom-right-radius: 25px;
    border-bottom-left-radius: 25px;
    border: 2px solid rgb(255, 255, 255);
    -webkit-box-shadow: rgb(0, 0, 0) 2px 4px 3px 0px;
    box-shadow: rgb(0, 0, 0) 2px 4px 3px 0px;
    margin-right: 0%;
    background-color: rgba(255, 249, 249, 0.909804);
}

#box21 {
    float: left;
    height: 20.498321%;
    margin: 4% 2% 2%;
    clear: none;
    width: 20.984848%;
    display: block;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
    background-color: rgb(255, 255, 255);
}

#box22 {
    float: left;
    height: 20.498321%;
    margin: 4% 2% 2%;
    clear: none;
    width: 20.984848%;
    display: block;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
    background-color: rgb(255, 255, 255);
}

#box23 {
    float: left;
    height: 20.498321%;
    margin: 4% 2% 2%;
    clear: both;
    width: 20.984848%;
    display: block;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
    background-color: rgb(255, 255, 255);
}

#box24 {
    float: left;
    height: 20.498321%;
    margin: 4% 2% 2%;
    clear: none;
    width: 20.984848%;
    display: block;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
    background-color: rgb(255, 255, 255);
}

#box25 {
    float: left;
    height: 20.498321%;
    margin: 4% 2% 2%;
    clear: none;
    width: 20.984848%;
    display: block;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
    background-color: rgb(255, 255, 255);
}

#box26 {
    float: left;
    height: 20.498321%;
    margin: 4% 2% 2%;
    clear: none;
    width: 20.984848%;
    display: block;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
    background-color: rgb(255, 255, 255);
}

#box27 {
    float: left;
    height: 20.498321%;
    margin: 4% 2% 2%;
    clear: both;
    width: 20.984848%;
    display: block;
    border: 1px solid rgb(208, 0, 0);
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
    background-color: rgb(214, 68, 52);
}

#box28 {
    float: left;
    height: 20.498321%;
    margin: 4% 2% 2%;
    clear: none;
    width: 20.984848%;
    display: block;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
    background-color: rgb(255, 255, 255);
}

#box29 {
    float: left;
    height: 20.498321%;
    margin: 4% 2% 2%;
    clear: none;
    width: 20.984848%;
    display: block;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
    background-color: rgb(255, 255, 255);
}

#box30 {
    float: left;
    height: 20.498321%;
    margin: 4% 2% 2%;
    clear: none;
    width: 20.984848%;
    display: block;
    border: 1px solid rgb(208, 0, 0);
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
    background-color: rgb(214, 68, 52);
}

#_4_ESQUINAS_GANAN {
    float: left;
    font-size: 2em;
    width: 100%;
    height: auto;
    text-align: center;
    font-weight: 700;
    line-height: 1em;
    clear: both;
    min-height: 0px;
    display: block;
    font-family: bello-caps;
    color: rgb(0, 0, 0);
    position: static;
    top: 0px;
    left: 0px;
    padding-bottom: 0%;
    margin-top: 14.5px;
    margin-left: -0.0103826%;
    margin-right: 0px;
    min-width: 0px;
}

#Span {
    float: none;
    font-size: 35px;
    line-height: 1em;
    color: rgb(227, 0, 0);
}

.i_regresar {
    position: absolute;
    right: 10px;
    font-size: 30px;
    

    text-align: left;
    font-weight: normal;
    line-height: 1em;
    margin-left: 0px;
    margin-top: 20px;
    clear: none;
    min-height: 30px;
    color: rgb(255, 255, 255);
    margin-right: 1%;
}


#i_vercartas {
    float: right;
    height: 35px;
    font-size: 1em;
    margin: 0px 1.4375px 0px 0px;
    clear: none;
    width: 90px;
    font-family: bilo;
    text-align: center;
}

#formgroup {
    float: left;
    height: 49px;
    display: block;
    text-align: left;
    margin: 0px;
    clear: none;
    width: auto;
}

#i_buscar {
    float: left;
    height: 49px;
    padding: 0px 0px 0px 10px;
    font-size: 28px;
    display: block;
    width: 235.140625px;
    clear: none;
    margin-left: 20px;
    margin-right: 0px;
    margin-top: 0px;
    font-family: bilo;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
    border-style: none;
    border-width: 1px;
}

#labelBuscarJugador {
    float: left;
    font-size: 28px;
    width: auto;
    height: auto;
    text-align: left;
    font-weight: 500;
    line-height: 1em;
    padding: 0.2em 0em;
    display: block;
    margin-left: 0%;
    margin-right: 0px;
    clear: none;
    font-family: bilo;
    color: rgba(255, 249, 249, 0.909804);
}

#UIAclaracion {
    background-color: rgba(0, 0, 0, 0.631373);
    top: 0px;
    right: 0px;
    left: 0px;
    bottom: 0px;
    min-width: 0px;
    position: absolute;
    z-index:2;
}

#close {
    float: right;
    font-size: 30px;
    width: auto;
    height: auto;
    text-align: left;
    font-weight: normal;
    line-height: 1em;
    margin: 11px 0px 0px;
    clear: none;
    min-height: 30px;
    color: rgb(255, 255, 255);
    padding: 0px;
}

#UIBuscador {
    float: none;
    height: auto;
    margin: 20px auto;
    clear: none;
    width: 98%;
    min-width: 0px;
    padding-right: 2%;
    max-width: 98%;
    min-height: 0px;
}

#UIListadoJugadores {
    float: none;
    height: 150px;
    margin-left: auto;
    margin-top: 20px;
    clear: none;
    width: 97.95909%;
    margin-right: auto;
}

#d_nombre1 {
    float: left;
    font-size: 30px;
    width: auto;
    height: auto;
    text-align: left;
    font-weight: normal;
    line-height: 1em;
    margin: 2px 0px 0px;
    clear: none;
    min-height: 0px;
    color: rgba(255, 249, 249, 0.909804);
    font-family: bilo;
}

#UIJugador {
    float: none;
    height: auto;
    margin: 0px auto;
    clear: none;
    width: 98%;
    min-width: 0px;
    margin-bottom: 20px;
}

#d_buenas {
    float: right;
    font-size: 30px;
    width: auto;
    height: auto;
    text-align: left;
    font-weight: normal;
    line-height: 1em;
    margin: 0px 20px 0px 0px;
    clear: none;
    min-height: 0px;
    color: rgba(255, 249, 249, 0.909804);
    font-family: bilo;
}

#buscar {
    float: left;
    height: 49px;
    font-size: 1em;
    margin: 0px 0px 0px 20px;
    clear: none;
    width: 87.499999021875px;
    font-family: bilo;
    padding: 0px;
}

#d_listadoGanadores {
    float: none;
    font-size: 30px;
    width: 98%;
    height: auto;
    text-align: center;
    font-weight: normal;
    line-height: 1em;
    margin: 0px auto 10px;
    clear: none;
    min-height: 0px;
    font-family: bilo;
    padding-bottom: 0px;
}

#input {
    float: none;
    height: 49px;
    font-size: 1em;
    margin: 20px auto;
    clear: none;
    width: 159.625px;
    font-family: bilo;
}

#UICartasJuego {
    float: left;
    height: 100%;
    margin: 0px;
    clear: both;
    width: 96%;
    position: relative;
    min-width: 0px;
}

#centerGroup {
    float: none;
    height: auto;
    clear: none;
    width: 454.109375px;
    position: static;
    top: 0px;
    left: 0px;
    margin: 0px auto 20px;
    padding-top: 20px;
}

#uiactions {
    float: none;
    height: 65.984375px;
    margin: 0px auto;
    clear: none;
    width: 420px;
}

#Text_Input_Group {
    float: left;
    height: auto;
    display: block;
    text-align: left;
    margin: 0px;
    clear: none;
    width: 25.081845%;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
    border-bottom-left-radius: 6px;
  
}

#labelCantar {
    float: none;
    font-size: 1em;
    width: auto;
    height: auto;
    text-align: left;
    font-weight: normal;
    line-height: 1em;
    padding: 0.2em 0em;
    display: block;
    font-family: bilo;
    color: #559688;
}

.btn_AccionesStyle{
    float: left; 
    background-color: #f1d231;
     margin-top: 10px; margin-left: 10px; width: 50px; height: 50px;  border-radius: 30px; padding: 5px; padding-top: 8px;
     margin-left: 40px;
}
.seccionesDiv{
    width: 100%; height: 100%;
}
.tablaJugador{
    width: 100%;
    margin-top: 100px;
    z-index: -1;

}
.PremioJugador{
    font-family: bello-caps; font-size: 40px
}
.IconClass{width: 40px;  margin-top: -5px}
#i_cartaCantar {
    float: none;
    height: 43.609375px;
    padding: 0px;
    font-size: 30px;
    display: block;
    width: 100%;
    font-family: bilo;
    color: #559688;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
    background-color: #1a332d;
}

#i_deshacer {
    float: left;
    height: 65.984375px;
    margin: 0px 0px 0px 10.1875px;
    clear: none;
    width: 100px;
    background-color: rgb(25, 123, 55);
}

#text {
    float: none;
    font-size: 1em;
    width: 63.359375%;
    height: auto;
    text-align: left;
    font-weight: normal;
    line-height: 1em;
    margin-left: auto;
    margin-top: 22.375px;
    clear: none;
    min-height: 19px;
    font-family: bilo;
    color: rgb(255, 255, 255);
    margin-right: auto;
}

#i_rehacer {
    float: left;
    height: 65.984375px;
    margin: 0px;
    clear: none;
    width: 100px;
    display: block;
    background-color: rgb(25, 123, 55);
}

#text1 {
    float: none;
    font-size: 1em;
    width: 63.359375%;
    height: auto;
    text-align: left;
    font-weight: normal;
    line-height: 1em;
    margin-left: auto;
    margin-top: 22.375px;
    clear: none;
    min-height: 19px;
    font-family: bilo;
    color: rgb(255, 255, 255);
    margin-right: auto;
}

#i_vertificar {
    float: left;
    height: 65.984375px;
    margin: 0px;
    clear: none;
    width: 100px;
    display: block;
    background-color: rgb(25, 123, 55);
}

#text2 {
    float: none;
    font-size: 1em;
    width: 63.359375%;
    height: auto;
    text-align: left;
    font-weight: normal;
    line-height: 1em;
    margin-left: auto;
    margin-top: 22.375px;
    clear: none;
    min-height: 19px;
    font-family: bilo;
    color: rgb(255, 255, 255);
    margin-right: auto;
}

#UICarta {
    float: none;
    height: 582px;
    margin-left: auto;
    margin-top: 20px;
    clear: none;
    width: 388px;
    position: relative;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
    border: 2px solid rgb(255, 255, 255);
    -webkit-box-shadow: rgb(0, 0, 0) 0px 3px 10px 2px;
    box-shadow: rgb(0, 0, 0) 0px 3px 10px 2px;
    top: 0px;
    left: 0px;
    margin-right: auto;
    /*background-image:url("img/RANA.jpg");
    background-attachment: scroll;
    -webkit-background-size: 100%;
    background-size: 100%;
    background-position: 0% 0%;*/
    
}

#d_numero {
    float: right;
    font-size: 40px;
    width: 21.818034%;
    height: auto;
    text-align: center;
    font-weight: 800;
    line-height: 1em;
    margin: 9px 17.609375px 0px 0px;
    clear: none;
    min-height: 27px;
    font-family: bilo;
    display: block;
    min-width: 0px;
    text-shadow: rgb(255, 255, 255) 0px 5px 3px, rgb(255, 255, 255) 5px 3px 3px, rgb(255, 255, 255) 6px -4px 3px, rgb(255, 255, 255) -3px -4px 3px;
}

#d_nombre {
    float: left;
    font-size: 40px;
    width: 100%;
    height: auto;
    text-align: center;
    font-weight: 800;
    line-height: 1em;
    clear: both;
    min-height: 0px;
    font-family: bilo;
    display: block;
    min-width: 0px;
    position: absolute;
    top: auto;
    left: 0px;
    text-shadow: rgb(255, 255, 255) 6px -2px 3px, rgb(255, 255, 255) -3px -5px 5px, rgb(255, 255, 255) 5px 3px 3px, rgb(255, 255, 255) -7px 3px 10px;
    color: rgb(0, 0, 0);
    bottom: 70px;
    right: auto;
}

.ToolTipError{
    background-color: red;
    
    color:#fff;
    font-family: bilo;
    font-size: 18px;
    padding: 10px;
    border-radius: 10px;
    left: 10px;
    right: 10px;
    text-align: center;
    position: absolute;
    z-index: 1;
}